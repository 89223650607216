<template>
  <div>
    <v-page id="kamers" justify="center" align="center" class="d-flex text-center mt-16">
      <v-container>
        <v-row>
          <v-col class="font-weight-bold" style="font-size: 1rem;">{{ $t("vacature.header") }}</v-col>
        </v-row>
        <v-row>
          <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("vacature.subheader") }}</h1></v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" lg="8" xl="6">
            <i18n path="vacature.intro" tag="label" for="vacature.intro">
              
            </i18n>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="require('@/assets/poorterlogo.jpg')" :to="{name: 'Contact'}">
              {{ $t("vacature.text") }}
            </room-card>
          </v-col>
          
        </v-row>
        
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import RoomCard from "@/components/RoomCard";
import Footer from "@/views/Footer";
import VPage from "@/components/VPage";
import Rooms from  "@/data/rooms";
export default {
name: "Kamers",
  components: {VPage, RoomCard, Footer},
  data() {
  return {
    fotos: Rooms.map(x => x.fotos[0]),
    rooms: Rooms
  }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
