<template>
  <div>
    <v-page background-color="#F1EDED" class=" text-center py-5">
      <v-container v-if="blog">
        <v-row>
          <v-col><h1 class="c-primary font-weight-bold" style="font-size: 4rem;">{{ blogContent.title }}</h1></v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col v-html="blogContent.content">

              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-img :src="blog.featured_image" :aspect-ratio="16/9" contain></v-img>
          </v-col>
        </v-row>
        <hr style="border: 0; border-top: 1px solid #2B2B2B;margin-top: 10px;margin-bottom: 10px;width: 100%;">
        <v-row align="left" class="text-left">
          <v-col>
            
            <span>#</span>
            <a href="#" class="ml-3">{{ blogCategory }}</a>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/views/Footer";
import axios from "../http";
import {localeDate} from "../util/localeDate";
import VPage from "../components/VPage";
export default {
  name: "BlogSingle",
  components: {Footer, VPage},
  data() {
    return {
      blog: null
    }
  },
  computed: {
    blogContent() {
      return this.blog.content.find(x => x.code === this.$i18n.locale);
    },
    blogDate() {
      return localeDate(this.blog.created_at).format('LL');
    },
    blogCategory() {
      if (!this.blog.category)
        return "Algemeen";
      return this.blog.category.translations[this.$i18n.locale];
    }
  },
  async mounted() {
    let resp = await axios.get(`/p/${this.$route.params.id}`);
    this.blog = resp.data.data;
  }
}
</script>

<style scoped>

</style>